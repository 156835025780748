function FormatNumbers(value) 
{
    if (value >= 1_000_000) 
    {
        return (value / 1_000_000).toFixed(2) + 'm';
    }
    else if (value >= 1_000) 
    {
        return (value / 1_000).toFixed(1) + 'k';
    } 
    else 
    {
        return value.toString();
    }
}

export default FormatNumbers;

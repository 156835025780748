import ConfigFile from "./ConfigFile";

async function TransferRequests(filePath, dataArray) 
{
    // url data
    let mainPath        = ConfigFile.AuthEndpoint;
    let mainEndpoint    = "auth/";
    let fileName        = filePath + ".php";
    let fullPath        = mainPath + mainEndpoint + fileName;

    try 
    {
        const response = await fetch(fullPath, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataArray),
        });

        if (!response.ok) 
        {
            throw new Error('Network response was not ok.');
        }

        const contentType = response.headers.get('content-type');
        let isValidJson = false;
        let responseBody = null;

        if (contentType && contentType.includes('application/json')) 
        {
            const data = await response.json();
            isValidJson = true;
            responseBody = data;
        }

        return { isValidJson, responseBody };
    } 
    catch (error) 
    {
        console.error('Error fetching data:', error.message);
        return { isValidJson: false, responseBody: null };
    }
}

export default TransferRequests;
import { useState } from 'react';
import ConfigFile from '../resources/ConfigFile';
import FormsButton from "./FormsButton";
import FormsInput from "./FormsInput";
import TransferRequests from "../resources/TransferRequests";

function Signup({ setCurrentDisplay, triggerToast, setEmailAddress })
{
    const [fullName, setFullName] = useState('');
    const [signupEmail, setSignupEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dob, setDob] = useState('');
    
    // signup function
    const SignupNewUser = async (event, initialText) => 
    {
        // dislays logic
        event.target.disabled   = true;
        event.target.innerText  = 'Processing...';
        event.target.classList.add('animate-pulse');

        if (fullName.length > 0 && signupEmail.length > 0 && phoneNumber.length > 0 && dob.length > 0) 
        {
            let AccountData =
            {
                first_name: fullName,
                email_address: signupEmail,
                phone_number: phoneNumber,
                dob: dob
            };

            // send request to the back end
            let CreateNewAccount = await TransferRequests('signup', AccountData);

            event.target.disabled   = false;
            event.target.innerText  = initialText;
            event.target.classList.remove('animate-pulse');

            if(CreateNewAccount.isValidJson === true)
            {
                triggerToast(CreateNewAccount.responseBody[1]);

                // is successful
                if (CreateNewAccount.responseBody[0] === true) 
                {
                    // make the email form visible with the actual email
                    setEmailAddress(signupEmail);

                    setFullName('');
                    setSignupEmail('');
                    setPhoneNumber('');

                    setCurrentDisplay('Login');
                }
            }
            else
            {
                triggerToast(ConfigFile.ErrorProcessing);
            }
        }
        else 
        {
            event.target.disabled   = false;
            event.target.innerText  = initialText;
            event.target.classList.remove('animate-pulse');
            triggerToast('Please enter the required fields. They are necessary for processing your account creation');
        }
    }

    // render
    return(
        <div className="w-full">
            <div className="w-full space-y-4">
                <FormsInput 
                    title="Full Name" 
                    type="text" 
                    icon="user-plus" 
                    placeholder="John Doe" 
                    currentValue={fullName} 
                    setValue={setFullName} 
                />
                <FormsInput 
                    title="Email Address" 
                    type="email" 
                    icon="envelope" 
                    placeholder="Eg. johndoe@gmail.com" 
                    currentValue={signupEmail} 
                    setValue={setSignupEmail} 
                />
                <FormsInput 
                    title="Phone Number" 
                    type="number" 
                    icon="phone" 
                    placeholder="Eg. 07xxxxxx25" 
                    currentValue={phoneNumber} 
                    setValue={setPhoneNumber} 
                />
                <FormsInput 
                    title="DOB" 
                    type="date" 
                    icon="calendar-alt" 
                    currentValue={dob} 
                    setValue={setDob} 
                />
                <div className="w-full text-xs py-4 text-green-600">
                    <p><span className="font-bold">NB:</span> password will be sent to your email.</p>
                </div>
                <FormsButton text="Signup" logicOnClick={SignupNewUser} />
                <div className="w-full py-4 text-center">
                    <p className="text-gray-500 text-xs cursor-pointer" onClick={() => setCurrentDisplay('Login')}>
                        Already have an account? <span className="underline">Login here</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Signup;
import {useState, useEffect } from "react";
import FormsInput from "./FormsInput";
import FormsButton from './FormsButton';
import WrapJsCookies from "../resources/Cookies";
import ConfigFile from "../resources/ConfigFile";
import TransferRequests from "../resources/TransferRequests";
import { useToast } from '../resources/ToastContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccountEntry from "./AccountEntry";
import { useNavigate } from "react-router-dom";

function Account() 
{
    const navigate = useNavigate();
    const [newExpenditure, setNewExpenditure] = useState('');
    const [myExpenditures, setMyExpenditures] = useState([]);
    const [account, setAccount] = useState([]);
    const triggerToast = useToast();

    // read existing account info
    useEffect(() => 
    {
        // read expenditures
        const ReadExpenditures = async ()  =>
        {
            let logCookie = WrapJsCookies.getCookie(ConfigFile.AuthCookie);
    
            let ReadData = 
            {
                access_token: logCookie
            };
    
            // send request to the back end
            let ReadExpenditure = await TransferRequests('expenditures', ReadData);

            if (ReadExpenditure.isValidJson === true) 
            {
                if (ReadExpenditure.responseBody[0] === true) 
                {
                    // set to the state 
                    setMyExpenditures(ReadExpenditure.responseBody[1]);
                }
                else
                {
                    triggerToast(ReadExpenditure.responseBody[1]);
                }
            }
            else
            {
                triggerToast("Error fetching my expenditures");
            }
        }

        ReadExpenditures();

    }, [triggerToast]);

    // add new expenditure
    const AddNewExpenditure = async (event, initialText) =>
    {
        // dislays logic
        event.target.disabled   = true;
        event.target.innerText  = 'Processing...';
        event.target.classList.add('animate-pulse');

        if (newExpenditure.length > 0) 
        {
            if (window.confirm('Do you wish to proceed?')) 
            {
                let logCookie = WrapJsCookies.getCookie(ConfigFile.AuthCookie);
                let NewData = 
                {
                    expenditure: newExpenditure,
                    access_token: logCookie
                };

                // send request to the back end
                let NewExpenditure = await TransferRequests('add_expenditure', NewData);
                
                event.target.disabled   = false;
                event.target.innerText  = initialText;
                event.target.classList.remove('animate-pulse');

                if(NewExpenditure.isValidJson === true)
                {
                    triggerToast(NewExpenditure.responseBody[1]);

                    // is successful
                    if (NewExpenditure.responseBody[0] === true) 
                    {
                        // append the new value to the existing
                        setMyExpenditures(myExpenditures => [...myExpenditures, NewExpenditure.responseBody[2]]);

                        setNewExpenditure('');
                    }
                }
                else
                {
                    triggerToast(ConfigFile.ErrorProcessing);
                }
            }
            else
            {
                event.target.disabled   = false;
                event.target.innerText  = initialText;
                event.target.classList.remove('animate-pulse');
            }
        }
        else
        {
            event.target.disabled   = false;
            event.target.innerText  = initialText;
            event.target.classList.remove('animate-pulse');
            triggerToast('Please enter the required fields. They are necessary for processing your account access');
        }
    }

    // on chnage the percentage inputs
    function OnchangePercentages(newPercent, currentToken) 
    {
        if (newPercent === null || newPercent <= 0 ) 
        {
            newPercent = 0;
        }
        
        const updatedExpenditures = myExpenditures.map(expense => 
        {
            if (expense.access_token === currentToken) 
            {
                return { ...expense, percent: newPercent };
            } 
            else 
            {
                return expense;
            }
        });
    
        setMyExpenditures(updatedExpenditures);
    }

    // count all the balance percentages
    function RemainingPercenatge()
    {
        let total_count = 0;
        for (let index = 0; index < myExpenditures.length; index++) 
        {
            const element = myExpenditures[index];
            total_count = parseFloat(element.percent) + total_count;
        }
        
        let remaining = 100 - total_count;
        

        return (remaining <= 100 && remaining >= 0)? remaining : remaining === isNaN? 'All fields are required to get ': 'Total must be in range 0 - 100';
    }

    // database update the values
    const UpdatePercentages = async (event, initialText) =>
    {
        // dislays logic
        event.target.disabled   = true;
        event.target.innerText  = 'Processing...';
        event.target.classList.add('animate-pulse');

        if (myExpenditures.length > 0) 
        {
            if (window.confirm('Do you wish to proceed and update the new values?')) 
            {
                let logCookie = WrapJsCookies.getCookie(ConfigFile.AuthCookie);
                let NewData = 
                {
                    new_values: myExpenditures,
                    access_token: logCookie
                };

                // send request to the back end
                let UpdateExpenditure = await TransferRequests('upd_percentages', NewData);

                event.target.disabled   = false;
                event.target.innerText  = initialText;
                event.target.classList.remove('animate-pulse');

                if(UpdateExpenditure.isValidJson === true)
                {
                    triggerToast(UpdateExpenditure.responseBody[1]);
                }
                else
                {
                    triggerToast(ConfigFile.ErrorProcessing);
                }
            }
            else
            {
                event.target.disabled   = false;
                event.target.innerText  = initialText;
                event.target.classList.remove('animate-pulse');
            }
        }
        else
        {
            event.target.disabled   = false;
            event.target.innerText  = initialText;
            event.target.classList.remove('animate-pulse');
        }
    }

    // remove entry from the entries in the array
    function removeExpenditure(accessToken) 
    {
        setMyExpenditures(myExpenditures => myExpenditures.filter(expense => expense.access_token !== accessToken));
    }

    // disable a saving
    const DisableExpenditure = async (access_token) =>
    {
        if (window.confirm('Do you wish to proceed? This is not reversable?')) 
        {
            let logCookie = WrapJsCookies.getCookie(ConfigFile.AuthCookie);
            let NewData = 
            {
                new_update: access_token,
                access_token: logCookie
            };

            // send request to the back end
            let UpdateExpenditure = await TransferRequests('upd_status', NewData);

            if(UpdateExpenditure.isValidJson === true)
            {
                triggerToast(UpdateExpenditure.responseBody[1]);

                // update the existing my expenditure
                removeExpenditure(access_token);
            }
            else
            {
                triggerToast(ConfigFile.ErrorProcessing);
            }
        }

    }

    // read the account data/information
    useEffect(() => 
    {
        const ReadAccountData = async () =>
        {
            let logCookie = WrapJsCookies.getCookie(ConfigFile.AuthCookie);

            let AccountData = 
            {
                access_token: logCookie
            };

            // send request to the back end
            let ReacAccount = await TransferRequests('account', AccountData);

            if (ReacAccount.isValidJson === true) 
            {
                if (ReacAccount.responseBody[0] === true) 
                {
                    // set to the state 
                    setAccount(ReacAccount.responseBody[1]);
                }
                else
                {
                    triggerToast(ReacAccount.responseBody[1]);
                }
            }
            else
            {
                triggerToast("Error fetching my account data");
            }
        }

        ReadAccountData();
        
    }, [triggerToast]);

    // logout
    function Logout() 
    {
        WrapJsCookies.clearCookie(ConfigFile.AuthCookie);
        navigate('/');
    }

    // render
    return(

        <div className="w-full h-full flex flex-col space-y-5 sm:space-y-0 sm:flex-row sm:space-x-5">
            {/* main account */}
            <div className="w-full md:w-1/2">
                <p className="font-semibold mb-4 px-4">Account Information</p>
                <div className="w-full">
                    <div className="px-4 w-full">
                        {
                            account.length > 0?
                            (
                                account.map((element, index) =>
                                (
                                    <div className="w-full space-y-10" key={index}>
                                        <AccountEntry icon="user" text={element.full_name} title="Full Name" />
                                        <AccountEntry icon="envelope" text={element.email_address} title="Email Address" />
                                        <AccountEntry icon="envelope-circle-check" text={element.email_verified === 1? 'True': 'False'} title="Email Verified" />
                                        <AccountEntry icon="phone" text={element.phone_number} title="Phone Number" />
                                        <AccountEntry icon="calendar-alt" text={element.dob} title="DOB" />
                                        <AccountEntry icon="circle-check" text={element.account_active === 1? 'Active': 'Inactive'} title="Account Status" />
                                        <AccountEntry icon="calendar-check" text={element.date_created} title="Date Created" />
                                    </div>
                                ))
                            ):
                            (
                                <p className="text-gray-500 animate-pulse">Loading ...</p>
                            )
                        }
                    </div>
                </div>

                {/* logout */}
                <div className="mt-10 w-[150px]">
                    <FormsButton text="Logout" logicOnClick={Logout} />
                </div>
            </div>

            {/* expenditures */}
            <div className="w-full md:w-1/2 pb-5">
                <p className="font-semibold mb-4 px-4">Expenditure Information</p>
                <div className="w-full">
                    <div className="w-full space-y-1 mb-4 px-4">
                        <FormsInput type="text" icon="comment-dollar" placeholder="Eg. Savings" currentValue={newExpenditure} setValue={setNewExpenditure}  />
                        <FormsButton text="Add" logicOnClick={AddNewExpenditure} />
                    </div>
                </div>

                {/* display the expenditures */}
                <p className="font-semibold mb-4 px-4 mt-20">Expenditure Percentages</p>
                <div className="w-full px-4">
                    {
                        myExpenditures.length > 0?
                        (
                            myExpenditures.map((element, index) =>
                            (
                                <div key={index} className="flex flex-row justify-between items-center h-[25px] my-4">
                                    <p className="text-gray-500">{element.expense_name}</p>
                                    <div className="w-auto space-x-2 flex flex-row items-center h-full">
                                        <input 
                                            type="number" 
                                            maxLength={0}
                                            className="shadow-bs-3 w-[100px] h-[25px] p-2 outline-none text-center" 
                                            value={element.percent} 
                                            onChange={(event) => OnchangePercentages(event.target.value, element.access_token)} 
                                        /> 

                                        {/* disable each */}
                                        <div className="w-[30px] shadow-bs-5 h-full flex flex-row items-center justify-center rounded-sm">
                                            <FontAwesomeIcon icon="trash-alt" className="text-red-600 cursor-pointer" onClick={() => DisableExpenditure(element.access_token)} />
                                        </div>
                                    </div>
                                </div>  
                            ))
                        ):
                        (
                            <p className="text-red-500">Please insert an expenditure to proceed. All coins deposited will 100% remain as balance</p>
                        )
                    }

                    <div className="w-full my-10 flex flex-row justify-end">
                        {
                            myExpenditures.length > 0 &&
                            (
                                <FormsButton text="Update" logicOnClick={UpdatePercentages} />
                            )
                        }
                    </div>

                    {/* count the usages */}
                    <div className="w-full mt-4 font-semibold flex flex-row justify-between items-center">
                        <p className="text-green-600">Remaining Percentage: </p>
                        <div className="shadow-bs-3 w-auto min-w-[100px] h-[25px] p-1 bg-green-600 text-white text-center">
                            <p>{RemainingPercenatge()}%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );    
}

export default Account;
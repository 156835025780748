import Cookies from 'js-cookie';

// create cookies
function createCookie(cookieName, cookieValue, expiresInDays) 
{
    Cookies.set(cookieName, cookieValue, { expires: expiresInDays });
    return true;
}

// get cookie name
function getCookie(cookieName) 
{
    const name = Cookies.get(cookieName);
    return name !== undefined ? name : null;
}


// clear a cookie
function clearCookie(name) 
{
    Cookies.remove(name);
    return true;
}

const WrapJsCookies = { createCookie, getCookie, clearCookie };

export default WrapJsCookies;

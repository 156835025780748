const ConfigFile =
{
    "AuthEndpoint": "https://savingsendpoints.mathiweb.com/",
    "ErrorProcessing": "Error processing request. Please try again later",
    "AuthCookie": "pt_cookie"
}
//https://savingsendpoints.mathiweb.com/
//http://www.localhost/end/savings/
export default ConfigFile;


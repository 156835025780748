import { useState } from 'react';
import FormsButton from "./FormsButton";
import FormsInput from "./FormsInput";
import TransferRequests from "../resources/TransferRequests";
import ConfigFile from '../resources/ConfigFile';
import WrapJsCookies from "../resources/Cookies";
import { useNavigate } from 'react-router-dom';

function Login({ setCurrentDisplay, emailAddress, setEmailAddress, triggerToast })
{
    const navigate = useNavigate();
    const [password, setPassword] = useState('');

    // login function
    const LoginAccount = async(event, initialText) =>
    {
        // dislays logic
        event.target.disabled   = true;
        event.target.innerText  = 'Processing...';
        event.target.classList.add('animate-pulse');

        if(emailAddress.length > 0 && password.length > 0)
        {
            let LoginData = 
            {
                login_password: password,
                login_email: emailAddress
            };

            let NewLogin = await TransferRequests('login', LoginData);

            event.target.disabled   = false;
            event.target.innerText  = initialText;
            event.target.classList.remove('animate-pulse');

            if (NewLogin.isValidJson === true) 
            {
                triggerToast(NewLogin.responseBody[1]);
                
                if (NewLogin.responseBody[0] === true) 
                {
                    // clear the fields
                    setPassword('');
                    setEmailAddress('');

                    // set the cookies
                    const expiresInDays     = 30;

                    WrapJsCookies.createCookie(ConfigFile.AuthCookie, NewLogin.responseBody[2], expiresInDays );

                    // navigate to the dashboard
                    navigate('/home');
                }
            }
            else
            {
                triggerToast(ConfigFile.ErrorProcessing);
            }
        }
        else
        {
            event.target.disabled   = false;
            event.target.innerText  = initialText;
            event.target.classList.remove('animate-pulse');
            triggerToast('Please enter the required fields. They are necessary for processing your account access');
        }
    }

    // render
    return(
        <div className="w-full">
            <div className="w-full space-y-4">
                <FormsInput 
                    title="Email Address" 
                    type="email" 
                    icon="envelope" 
                    placeholder="Eg. johndoe@gmail.com" 
                    currentValue={emailAddress} 
                    setValue={setEmailAddress} 
                />
                <FormsInput 
                    title="Password" 
                    type="password" 
                    icon="lock" 
                    currentValue={password} 
                    setValue={setPassword} 
                />
                <div className="w-full text-end py-2">
                    <p className="text-gray-500 text-xs cursor-pointer" onClick={() => setCurrentDisplay('Reset')}>Forgot Password?</p>
                </div>
                <FormsButton text="Login" logicOnClick={LoginAccount} />
                <div className="w-full py-4 text-center">
                    <p className="text-gray-500 text-xs cursor-pointer" onClick={() => setCurrentDisplay('Signup')}>Do not have an account? <span className="underline">Signup here</span></p>
                </div>
            </div>
        </div>
    );
}

export default Login;
import { useState } from 'react';
import FormsButton from "./FormsButton";
import FormsInput from "./FormsInput";
import TransferRequests from "../resources/TransferRequests";
import ConfigFile from '../resources/ConfigFile';

function Reset({ setCurrentDisplay, setEmailAddress, triggerToast })
{
    const [resetEmail, setResetEmail] = useState('');
    
    // signup function
    const ResetPassword = async (event, initialText) => 
    {
        // dislays logic
        event.target.disabled   = true;
        event.target.innerText  = 'Processing...';
        event.target.classList.add('animate-pulse');

        if (resetEmail.length > 0) 
        {
            let AccountData =
            {
                reset_email: resetEmail
            };

            // send request to the back end
            let CreateNewAccount = await TransferRequests('reset', AccountData);

            event.target.disabled   = false;
            event.target.innerText  = initialText;
            event.target.classList.remove('animate-pulse');

            if(CreateNewAccount.isValidJson === true)
            {
                triggerToast(CreateNewAccount.responseBody[1]);

                // is successful
                if (CreateNewAccount.responseBody[0] === true) 
                {
                    // make the email form visible with the actual email
                    setEmailAddress(resetEmail);

                    setResetEmail('');

                    setCurrentDisplay('Login');
                }
            }
            else
            {
                triggerToast(ConfigFile.ErrorProcessing);
            }
        }
        else 
        {
            event.target.disabled   = false;
            event.target.innerText  = initialText;
            event.target.classList.remove('animate-pulse');
            triggerToast('Please enter the required fields. They are necessary for processing your account creation');
        }
    }

    // render
    return(
        <div className="w-full">
            <div className="w-full space-y-4">
                <FormsInput 
                    title="Registered Email" 
                    type="email" 
                    icon="envelope" 
                    placeholder="Eg. johndoe@gmail.com" 
                    currentValue={resetEmail} 
                    setValue={setResetEmail} 
                />
                <div className="w-full text-end py-2">
                    <p className="text-gray-500 text-xs cursor-pointer" onClick={() => setCurrentDisplay('Signup')}>Create a new Account?</p>
                </div>
                <FormsButton text="Reset" logicOnClick={ResetPassword} />
                <div className="w-full py-4 text-center">
                    <p className="text-gray-500 text-xs cursor-pointer" onClick={() => setCurrentDisplay('Login')}>Already have an account? <span className="underline">Login here</span></p>
                </div>
            </div>
        </div>
    );
}

export default Reset;
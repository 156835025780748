import {useState, useEffect } from "react";
import WrapJsCookies from "../resources/Cookies";
import ConfigFile from "../resources/ConfigFile";
import TransferRequests from "../resources/TransferRequests";
import { useToast } from '../resources/ToastContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

function Dashboard() 
{
    let navigate = useNavigate();
    const triggerToast = useToast();
    const [homeExpenditures, setHomeExpenditures] = useState([]);

    // dashboard deposits
    // anytime deposit chnages update
    useEffect(() => 
    {
       // read expenditures
       const ReadExpenditures = async ()  =>
        {
            let logCookie = WrapJsCookies.getCookie(ConfigFile.AuthCookie);
    
            let ReadData = 
            {
                access_token: logCookie
            };
    
            // send request to the back end
            let ReadExpenditure = await TransferRequests('dashboard', ReadData);

            if (ReadExpenditure.isValidJson === true) 
            {
                if (ReadExpenditure.responseBody[0] === true) 
                {
                    // set to the state 
                    setHomeExpenditures(ReadExpenditure.responseBody[1]);
                }
                else
                {
                    triggerToast(ReadExpenditure.responseBody[1]);
                }
            }
            else
            {
                triggerToast("Error fetching my expenditures");
            }
        }

        ReadExpenditures();

    }, [triggerToast]);

    // render
    return(

        <div className="w-full space-y-10">
            {/* new deposit */}
            <div className="w-full flex flex-row justify-between items-center">
                <button className="bg-main-blue outline-none shadow-bs-3 py-2 px-4 rounded-sm text-white space-x-2 text-xs" onClick={() => navigate('/deposit')}>
                    <FontAwesomeIcon icon="coins" /> 
                    <span>Deposit Coins</span>
                </button>
                
                {/* account */}
                <div className="w-auto">
                    <button className="outline-none py-2 px-4 rounded-sm text-main-dark space-x-2 text-xs" onClick={() => navigate('/account')}>
                        <FontAwesomeIcon icon="coins" /> 
                        <span>Account Settings</span>
                    </button>
                </div>
            </div>

            {/* display all existing coins */}
            <div className="w-full space-y-8">
                {
                    homeExpenditures.length > 0?
                    (
                        homeExpenditures.map((element, index) =>
                        (
                            <div className="w-full" key={index}>
                                <div className="w-full flex flex-col pb-4 space-y-4 sm:space-y-0 sm:space-x-4 sm:flex-row">
                                    {/* about deposit */}
                                    <div className="w-full space-y-3 sm:w1/2">
                                        <p className="font-semibold uppercase">Deposit: <span className="text-orange-600">#{ element.access_token }</span></p>
                                        <div className="w-full text-gray-500 flex flex-grow space-x-4 items-center">
                                            <FontAwesomeIcon icon="calendar-alt" /> 
                                            <span>{ element.date_added }</span>
                                        </div>
                                        <div className="w-full text-gray-500 flex flex-grow space-x-4 items-center">
                                            <FontAwesomeIcon icon="coins" /> 
                                            <span>{ element.dep_amount }</span>
                                        </div>
                                        <div className="w-full text-gray-500 flex flex-grow space-x-4 items-center">
                                            <FontAwesomeIcon icon="flag" /> 
                                            <span>{ element.deposit_status === 1? "Active": "Inactive" }</span>
                                        </div>
                                    </div>
    
                                    {/* deposit expenses */}
                                    <div className="w-full space-y-3 sm:w1/2">
                                        {
                                            element.expenses.length > 0?
                                            (
                                                element.expenses.map((expense, index_e) =>
                                                (
                                                    <div className="w-full flex flex-col space-y-3" key={index_e}>
                                                        <p><span className="text-gray-500 mr-4">Expense: </span>{ expense.dist_expense }</p>
                                                        <p><span className="text-gray-500 mr-4">Amount: </span>{ expense.dist_amount }</p>
                                                    </div>
                                                ))
                                            ):
                                            (
                                                <p>No expenses found for this depsoit.</p>
                                            )
                                        }
                                    </div>
                                </div>
                                <hr />
                            </div>
                        ))
                    ):
                    (
                        <p>No coins deposited yet. Go to account settings, add expeses then go to deposit coins to get started</p>
                    )
                }
            </div>
        </div>
    ); 
}

export default Dashboard;
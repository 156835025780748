function FormsButton({ text, logicOnClick }) 
{
    // render
    return (
        <div className="w-full">
            <button
                className="w-full bg-main-blue outline-none border-none py-2 shadow-bs-3 text-white text-xs hover:bg-inherit hover:text-main-blue rounded-sm"
                onClick={(event) => logicOnClick(event, event.target.innerText)}
            >
                {text}
            </button>
        </div>
    );
}

export default FormsButton;
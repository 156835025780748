// App.js
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Dashboard from './paths/Dashboard';
import NoPage from './paths/NoPage';
import Landing from './paths/Landing';
import { ToastProvider } from "./resources/ToastContext";
import "./resources/FontAwesome";
import Layout from './paths/Layout';
import Account from './paths/Account';
import Deposit from './paths/Deposit';

function App() {
    return (
        <ToastProvider>
            <div className="font-rubik bg-main-light text-main-dark w-screen h-screen overflow-y-auto tracking-wide text-xs">
                <BrowserRouter>
                    <Routes>
                        <Route index path="/" element={ <Landing /> } />
                        <Route element={ <Layout /> } >
                            <Route path="/home" element={ <Dashboard /> } />
                            <Route path="/account" element={ <Account /> } />
                            <Route path="/deposit" element={ <Deposit /> } />
                        </Route>
                        <Route path="*" element={<NoPage />} />
                    </Routes>
                </BrowserRouter>
            </div>
        </ToastProvider>
    );
}

export default App;
import { useState, useEffect } from 'react';
import Holder from "../resources/landingholder.png";
import Login from "./Login";
import Reset from './Reset';
import Signup from './Signup';
import { useToast } from '../resources/ToastContext';
import { useNavigate } from 'react-router-dom';
import WrapJsCookies from '../resources/Cookies';
import ConfigFile from '../resources/ConfigFile';

function Landing() 
{
    const navigate = useNavigate();
    const triggerToast = useToast();
    const [currentDisplay, setCurrentDisplay] = useState('Login');
    const [emailAddress, setEmailAddress] = useState('');

    // check if the cookie already set
    useEffect(() => 
    {
        const checkCookie = () => 
        {
            const cookie = WrapJsCookies.getCookie(ConfigFile.AuthCookie);
    
            if (cookie !== null && cookie !== "") 
            {
                // Cookie exists and is valid
                navigate('/home');
            }
        };
    
        checkCookie();

    }, [navigate]);

    // render
    return (
        <div className="w-full h-full p-2 flex flex-col justify-around items-center space-y-2">
            {/* hold the forms */}

            {/* main layout */}
            <div className="w-[98%] h-auto max-w-[400px] mx-auto bg-white shadow-bs-3 p-4 space-y-2">
                <div className="w-full space-y-3 flex flex-col items-center">
                    <p className="uppercase font-semibold text-lg">Welcome</p>
                    <span className="text-gray-400 text-xs">On board, to your account</span>
                    <img src={Holder} alt="Welcome" className="w-[60px] h-[60px]" />
                </div>

                {/* toggle */}
                <div className="w-full">
                    {/* login, reset, signup */}
                    {
                        currentDisplay === "Login"?
                        (
                            <Login setCurrentDisplay={setCurrentDisplay} triggerToast={triggerToast} emailAddress={emailAddress} setEmailAddress={setEmailAddress} />
                        ):
                        (
                            currentDisplay === "Reset"?
                            (
                                <Reset setCurrentDisplay={setCurrentDisplay} triggerToast={triggerToast} setEmailAddress={setEmailAddress} />
                            ):
                            (
                                <Signup setCurrentDisplay={setCurrentDisplay} triggerToast={triggerToast} setEmailAddress={setEmailAddress} />
                            )
                        )
                    }
                </div>
            </div>

            <div className="w-full text-center">
                <span className="text-xs text-gray-500">Copyright @ 2024</span>
            </div>

        </div>
    );
}

export default Landing;
import React, { createContext, useState, useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ToastContext = createContext();

export function ToastProvider({ children }) 
{
    const [toastVisible, setToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const triggerToast = useCallback((message) => 
    {
        setToastMessage(message);
        setToastVisible(true);

        setTimeout(() => 
        {
            setToastVisible(false);
        }, 15000);
    }, []);

    return (
        <ToastContext.Provider value={triggerToast}>
            {children}
            <div className={`fixed bottom-5 bg-white right-4 z-50 shadow-bs-5 rounded max-w-[200px] ${toastVisible ? 'opacity-100' : 'opacity-0'}`} style={{ transition: 'opacity 0.5s ease-in-out' }}>
                <div className="w-full flex flex-row justify-end p-2 cursor-pointer">
                    <div className="w-[27px] h-[27px] shadow-bs-5 flex flex-row justify-center items-center rounded-full">
                        <FontAwesomeIcon icon="xmark" onClick={() => setToastVisible(false)} />
                    </div>
                </div>
                <hr />
                <div className="w-full p-2">
                    <p className="text-xs">{toastMessage}</p>
                </div>
            </div>
        </ToastContext.Provider>
    );
}

export function useToast() 
{
    return useContext(ToastContext);
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FormsInput({ title, type = 'text', icon, placeholder = '', currentValue, setValue, isDisabled = false })
{
    const today = new Date().toISOString().split('T')[0];

    // render
    return(

        <div className="w-full space-y-2">
            <p className="text-gray-500">{title}</p>
            <div className="w-full flex flex-row items-center h-auto bg-blue-600">
                <input 
                    type={type} 
                    className={`flex-1 p-2 border border-gray-300 outline-none font-light bg-white text-semi-dark ${isDisabled ?'bg-gray-100':''}`} 
                    placeholder={placeholder}
                    value={currentValue}
                    onChange={(event) => setValue(event.target.value)}
                    max={type === 'date' ? today : undefined}
                    disabled={isDisabled}
                />
                <div className="w-[40px] flex flex-row justify-center items-center">
                    <FontAwesomeIcon icon={icon} className="text-white" />
                </div>
            </div>
        </div>
    );
}

export default FormsInput;
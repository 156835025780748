import m404 from "../resources/m404.webp";
import { useNavigate } from "react-router-dom";

function NoPage() {
    const navigate = useNavigate();

    // render
    return (

        <div className="w-full h-full flex flex-col justify-center items-center space-y-4 text-center p-2">
            {/* display the page not found */}
            <img src={m404} alt="404" className="w-[300px] h-[300px] md:w-[500px] md:h-[500px]" />

            <p className="font-semibold text-lg md:text-3xl">Oh no... We lost this page</p>
            <p className="text-xs md:text-sm">We searched everywhere but we could not find what you are looking for: <br />Let's find a better place for you to go</p>

            <div className="w-full">
                <button
                    className="w-auto py-2 px-10 cursor-pointer bg-main-dark text-white shadow-bs-4 outline-none border-none rounded-md mt-10 text-sm"
                    onClick={() => navigate('/')}
                >
                    Back Home
                </button>
            </div>
        </div>
    );
}

export default NoPage;
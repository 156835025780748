import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AccountEntry({ text, title, icon }) 
{
    // render
    return(

        <div className="w-full space-x-4 flex flex-row text-gray-500">
            <div className="w-auto">
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className="flex-1 space-y-2">
                <p>{title}</p>
                <p className="text-main-dark">{text}</p>
            </div>
        </div>
    );
}

export default AccountEntry;
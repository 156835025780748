import {useState, useEffect } from "react";
import FormsInput from "./FormsInput";
import FormsButton from './FormsButton';
import WrapJsCookies from "../resources/Cookies";
import ConfigFile from "../resources/ConfigFile";
import TransferRequests from "../resources/TransferRequests";
import { useToast } from '../resources/ToastContext';
import FormatNumber from "../resources/FormatNumbers";

function Deposit()
{
    const [myExpenditures, setMyExpenditures] = useState([]);
    const [deposit, setDeposit] = useState(0);
    const triggerToast = useToast();
    const [balance, setBalance] = useState(0);

    // anytime deposit chnages update
    useEffect(() => 
    {
        function CalculateBalance() 
        {
            // count all percentages
            let CountPercentages = 0;

            for (let index = 0; index < myExpenditures.length; index++) 
            {
                const element = myExpenditures[index];
                CountPercentages += element['percent'];
            }

            let Remaining = 100 - parseFloat(CountPercentages);
            setBalance(Remaining);
        }

        CalculateBalance();

    }, [myExpenditures]);

    // fetch the current expenditure structure
    useEffect(() => 
    {
        // read expenditures
        const ReadExpenditures = async ()  =>
        {
            let logCookie = WrapJsCookies.getCookie(ConfigFile.AuthCookie);
    
            let ReadData = 
            {
                access_token: logCookie
            };
    
            // send request to the back end
            let ReadExpenditure = await TransferRequests('expenditures', ReadData);

            if (ReadExpenditure.isValidJson === true) 
            {
                if (ReadExpenditure.responseBody[0] === true) 
                {
                    // set to the state 
                    setMyExpenditures(ReadExpenditure.responseBody[1]);
                }
                else
                {
                    triggerToast(ReadExpenditure.responseBody[1]);
                }
            }
            else
            {
                triggerToast("Error fetching my expenditures");
            }
        }

        ReadExpenditures();

    }, [triggerToast]);

    // deposit coins
    const DepositCoins = async (event, initialText) =>
    {
        // dislays logic
        event.target.disabled   = true;
        event.target.innerText  = 'Processing...';
        event.target.classList.add('animate-pulse');

        // get each of the percentages
        if (deposit > 0 && deposit !== null) 
        {
            // add the deposit
            let logCookie = WrapJsCookies.getCookie(ConfigFile.AuthCookie);
            let NewData = 
            {
                deposit: deposit,
                access_token: logCookie
            };

            // send request to the back end
            let NewDeposit = await TransferRequests('deposit', NewData);
            
            event.target.disabled   = false;
            event.target.innerText  = initialText;
            event.target.classList.remove('animate-pulse');

            if(NewDeposit.isValidJson === true)
            {
                triggerToast(NewDeposit.responseBody[1]);

                // is successful
                if (NewDeposit.responseBody[0] === true) 
                {
                    setDeposit(0);
                }
            }
            else
            {
                triggerToast(ConfigFile.ErrorProcessing);
            }
        }
        else
        {
            event.target.disabled   = false;
            event.target.innerText  = initialText;
            event.target.classList.remove('animate-pulse');
        }
    }

    // render
    return(

        <div className="w-full">
            
            {/* control the input */}
            <div className="w-full max-w-[350px] mx-auto space-y-10">
                <div className="w-full">
                    <p className="font-semibold mb-4">Deposit Coins</p>
                    <FormsInput 
                        icon="coins" 
                        title="Coins" 
                        type="number" 
                        placeholder="Eg. 999" 
                        currentValue={deposit} 
                        setValue={setDeposit} 
                        isDisabled={myExpenditures.length === 0}
                    />
                </div>

                {/* show the distribution */}
                <div className="w-full space-y-5">
                    <p className="font-semibold mb-4">Distribution</p>
                    {
                        myExpenditures.length > 0?
                        (
                            <div className="w-full FLEX flex-row justify-between items-center">
                                <table className="w-full">
                                    <thead>
                                        <tr className="font-semibold">
                                            <td>Expenditure</td>
                                            <td>Current Percent</td>
                                            <td>Total</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            myExpenditures.map((element, index) =>
                                            (
                                                <tr key={index}>
                                                    <td>{parseFloat(index) + 1 + ". " +  element.expense_name}</td>
                                                    <td>{element.percent}%</td>
                                                    <td>
                                                        { 
                                                            deposit !== null && (FormatNumber(Math.floor(parseFloat(element.percent / 100) * deposit)))
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        ):
                        (
                            <p className="text-red-500">Please insert an expenditure to proceed. All coins deposited will 100% remain as balance</p>
                        )
                    }
                </div>
                {/* show the balance */}
                <div className="w-full">
                    <p className="font-semibold mb-4">Balance After Expenditure</p>
                    <div className="shadow-bs-3 w-auto min-w-[100px] h-[25px] p-1 bg-green-600 text-white text-center">
                        <p>Balance: {FormatNumber(Math.floor((balance / 100) * deposit))}</p>
                    </div>
                </div>

                {/*  deposit button */}
                <div className="w-full flex flex-row justify-center">
                    {/* control button */}
                    <div className="w-[120px]">
                        <FormsButton text="Deposit" logicOnClick={DepositCoins} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Deposit;
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import WrapJsCookies from "../resources/Cookies";
import ConfigFile from "../resources/ConfigFile";
import { useNavigate } from "react-router-dom";

function Layout()
{
    const navigate = useNavigate();
    const [logCookie, setLogCookie] = useState('');

    useEffect(() =>
    {
        const checkCookie = () => 
        {
            const cookie = WrapJsCookies.getCookie(ConfigFile.AuthCookie);
    
            if (cookie === null || cookie === "") 
            {
                // Cookie exists and is valid
                navigate('/');
            }
            else
            {
                setLogCookie(cookie);
            }
        };
    
        checkCookie();
    },[navigate]);

    // render
    return(

        <div className="w-full h-full p-2 overflow-y-auto">
            {/* display the brought in code */}
            <div className="w-full h-full p-2 shadow-bs-3 bg-white min-w-[250px] overflow-x-auto max-w-[900px] mx-auto">
                <Outlet />
            </div>
        </div>
    );
}

export default Layout;